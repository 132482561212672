<template>
    <v-container  fluid>
    <v-row dense>
        <v-col lg="8" offset-lg="2">
            <v-card-title>
                    <v-btn
                        to="/deliveries"
                        color="#ffffff"
                        light
                        elevation="0"
                    >
                    <v-icon left large>mdi-chevron-left</v-icon>
                    </v-btn>
                    <h3>Edit Delivery Note</h3>
                    <!--Show secondary action button-->
                    <v-spacer></v-spacer>
                            <v-btn
                                elevation="2"
                                color="#e7e7f7"
                                light
                                class="ml-10"
                                @click="generateDeliveryNote"
                            >
                                <v-icon left>mdi-printer</v-icon>
                                Print Delivery Note
                            </v-btn>
                </v-card-title>
            <v-card>
                <!--Snackbar Notification-->
                <!--snackbar messages are called on firebase actions-->
                <v-snackbar
                    v-model="alertSnackbar"
                    :timeout="-1"
                    :color="alertSnackbarColor"
                    top
                >
                <v-icon left small>mdi-alert</v-icon>
                Sales Order {{this.sales_order_number}} has been {{cancelledOrUpdated}}
                    
                    <!--Update preview modal-->
                    <v-dialog
                              v-model="dialog"
                              max-width="480"
                            >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="updatedOnly()"
                                text
                                v-bind="attrs"
                                v-on="on"
                            >View Updates<v-icon right>mdi-eye</v-icon></v-btn>
                          </template>
                          <!--Modal body-->
                          <v-card :draggable="true">
                              <v-card-title>
                                  <h3>Updated Order ({{this.sales_order_number}})</h3>
                              </v-card-title>
                              <v-container>
                                <v-list v-for="item in updated_line_items" :key="item.product_name">
                                    <v-list-item>
                                        <v-list-item-content>
                                            {{item.item_qty}}x {{item.product_name}}
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <v-card-actions>
                                <v-btn
                                    @click="updateAllWorkOrderItems()"
                                    color="#33cc99"
                                    class="white-text"
                                >Update All Items To Delivery Note</v-btn>
                              </v-card-actions>
                              </v-container>
                          </v-card>
                    </v-dialog>
                <template v-slot:action="{ attrs }">
                    <v-btn
                    icon
                    right
                    v-bind="attrs"
                    @click="alertSnackbar = false"
                    >
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                </v-snackbar>


                <!--New Delivery Note form -->
                <v-form @submit.prevent="updateDeliveryNote" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <h4>Delivery Note Details</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <!--Delivery Note number-->
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    :value="delivery_note_number"
                                    label="Delivery Note #"
                                    readonly
                                    single-line
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <!--Sales Order # is filled in automatically-->
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="sales_order_number"
                                    readonly
                                    cache-items
                                    label="Sales Order #"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <!--Delivery Due Date Picker-->
                            <v-col cols="12" sm="6" md="6">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="true"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="delivery_dispatch_date"
                                        label="Dispatch Date"
                                        append-icon="mdi-calendar"
                                        
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        dense
                                        outlined
                                        clearable
                                        color="#3d2cdd"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="delivery_dispatch_date"
                                        no-title
                                        scrollable
                                        color="#3d2cdd"
                                        show-adjacent-months
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="#ce2458"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="#33cc99"
                                        @click="$refs.menu.save(date)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <!--Delivery status-->
                            <v-col cols="12" sm="12" md="6">
                                <v-select
                                    v-model="delivery_status"
                                    required
                                    :items="delivery_status_items"
                                    label="Delivery Status"
                                    color="#3d2cdd"
                                    outlined
                                    dense
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-switch
                                    v-model="useAlternativeAddress"
                                    label="Use alternative address"
                                    color="#33cc99"
                                ></v-switch>
                            </v-col>
                        </v-row>
                        <div v-if="useAlternativeAddress">
                        <!--Alternative address-->
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <h4>Alternative Address</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                    v-model="alternative_address_line_1"
                                    label="Address Line 1"
                                    append-icon="mdi-map-marker"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                    v-model="alternative_address_line_2"
                                    label="Address Line 2"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                             <v-col cols="12" sm="8" md="8">
                                 <v-text-field
                                    v-model="alternative_address_city"
                                    label="City"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                             </v-col>
                             <v-col cols="12" sm="4" md="4">
                                 <v-text-field
                                    v-model.number="alternative_address_zip"
                                    type="number"
                                    label="ZIP Code"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                             </v-col>
                        </v-row>
                        </div>
                        <!--End of alternative address-->
                        <!--Special instructions-->
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <h4>Special Instructions (Notes)</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea
                                    v-model="delivery_special_instructions"
                                    clearable
                                    counter
                                    color="#3d2cdd"
                                    outlined
                                    dense
                                >

                                </v-textarea>
                            </v-col>
                        </v-row>

                        <!--Line Items-->
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <h4>Line Items</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-form v-for="item in line_items" :key="item.product_name">
                                    <v-container>
                                        <v-row>
                                            <!--Product Name-->
                                            <v-col sm="4" md="4">
                                                <v-text-field
                                                    v-model="item.product_name"
                                                    label="Item"
                                                    color="#3d2cdd"
                                                    readonly
                                                />
                                            </v-col>
                                            <!--Quantity-->
                                            <v-col sm="2" md="2">
                                                <v-text-field
                                                    v-model.number="item.item_qty"
                                                    label="Qty Delivered"
                                                    color="#3d2cdd"
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                    outlined
                                                    dense
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-col>
                        </v-row>
                        
                        <!--Goods received by customer-->
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <h4>Goods received by customer</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <span class="caption">
                                    By signing this document with an electronic signature, I confirm that
                                    all items have been received in good order.
                                </span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <VueSignaturePad
                                id="signature"
                                width="100%"
                                height="250px"
                                ref="signaturePad"
                                />  
                            </v-col>  
                        </v-row> 
                        <v-row>
                            <v-col>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="clearCustomerSignature">
                                    Clear Signature
                                    </v-btn>
                                    <v-btn
                                        dark
                                        color="#33cc99"
                                        @click="saveCustomerSignature"
                                        class="ml-5"
                                    >
                                        {{signatureText}} <v-icon right>{{signatureIcon}}</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                        <!--Divider-->
                        <v-row class="my-3">
                            <v-col>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row> 
                       
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/deliveries'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <!--Delete delivery note button-->
                    <v-btn
                        @click='deleteDeliveryNote'
                        v-if="this.user_roles_list.flat().includes('deliveries_delete')"
                        color="#ce2458"
                        text
                        x-large
                        :loading="loading_delete"
                    >
                        Delete
                        <v-icon right>mdi-delete</v-icon>
                    </v-btn>
                    <!--Update delivery note button-->
                    <v-btn
                        @click="updateDeliveryNote"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading_update"
                    >
                        Update
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import db from '../../../components/firebaseInit'
//Mixins
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import mixin_InitDeliveryNote from '../mixins/mixin_InitDeliveryNote'
import mixinCompanyProfile from '../../../globalActions/mixin_CompanyProfile'
import mixin_PDFdeliveryNote from '../mixins/mixin_PDFdeliveryNote'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'EditDeliveryNote',
    mixins: [
        mixin_UserRoles, mixin_InitDeliveryNote, mixinCompanyProfile,
        mixin_PDFdeliveryNote
    ],

    data(){
        return{
            sales_order_id: null,
            sales_order_updated: false,
            sales_order_cancelled: false,
            updated_line_items: null,
            alertSnackbar: false,
            dialog: false,
            receipient_name: null,
            customer_signature: null,
            loading_update: false,
            loading_delete: false,
        }
    },
    //Database query that runs before the page loads
    beforeRouteEnter (to, from, next){
        db.collection('delivery_notes').where('delivery_id', '==', to.params.delivery_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc =>{
                //vm assigns all the values we want to put in the template
                next(vm => {
                    //Sets template values to those stored in the database
                    vm.delivery_id = doc.data().delivery_id
                    vm.delivery_note_number = doc.data().delivery_note_number
                    vm.sales_order_number = doc.data().sales_order_number
                    vm.sales_order_id = doc.data().sales_order_id 
                    vm.sales_order_updated = doc.data().sales_order_updated 
                    vm.sales_order_cancelled = doc.data().sales_order_cancelled
                    vm.updated_line_items = doc.data().updated_line_items
                    vm.customer = doc.data().customer 
                    vm.delivery_dispatch_date = doc.data().delivery_dispatch_date 
                    vm.delivery_status = doc.data().delivery_status 
                    vm.delivery_special_instructions = doc.data().delivery_special_instructions 
                    vm.line_items = doc.data().line_items 
                    vm.delivery_date_created = doc.data().delivery_date_created
                    vm.useAlternativeAddress = doc.data().useAlternativeAddress
                    vm.alternative_address_line_1 = doc.data().alternative_address_line_1
                    vm.alternative_address_line_2 = doc.data().alternative_address_line_2
                    vm.alternative_address_city = doc.data().alternative_address_city
                    vm.alternative_address_zip = doc.data().alternative_address_zip
                    vm.customer_signature = doc.data().customer_signature
                    vm.customer_signature_timestamp = doc.data().customer_signature_timestamp
                })
            })
        })
    },
    watch: {
        '$route': 'fetchData'
    },
    mounted(){
        //Triggers notification if sales order has been cancelled / deleted
        if(this.sales_order_cancelled == true || this.sales_order_updated == true){
            this.alertSnackbar = true
        }
    },
    computed: {

        cancelledOrUpdated(){
            return this.sales_order_cancelled === true ? "cancelled or deleted" : "updated"
        },
        alertSnackbarColor(){
            return this.sales_order_cancelled === true ? "#ce2458" : "#FFB300"
        },
        signatureText(){
            return this.customer_signature === null ? 'Accept And Sign' : 'Signature Saved'
        },
        signatureIcon(){
            return this.customer_signature === null ? 'mdi-pen' : 'mdi-check'
        }     
    },
    methods: {
        //Maps database data to template relative to the work order selected in this route
        fetchData (){
        db.collection('delivery_notes').where('delivery_id', '==', this.$route.params.delivery_id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.delivery_id = doc.data().delivery_id
                this.delivery_note_number = doc.data().delivery_note_number
                this.sales_order_number = doc.data().sales_order_number 
                this.sales_order_id = doc.data().sales_order_id  
                this.sales_order_cancelled = doc.data().sales_order_cancelled
                this.sales_order_updated = doc.data().sales_order_updated
                this.updated_line_items = doc.data().updated_line_items
                this.customer = doc.data().customer
                this.delivery_dispatch_date = doc.data().delivery_dispatch_date
                this.delivery_status = doc.data().delivery_status 
                this.delivery_special_instructions = doc.data().delivery_special_instructions
                this.line_items = doc.data().line_items
                this.delivery_date_created = doc.data().delivery_date_created  
                this.useAlternativeAddress = doc.data().useAlternativeAddress,
                this.alternative_address_line_1 = doc.data().alternative_address_line_1,
                this.alternative_address_line_2 = doc.data().alternative_address_line_2,
                this.alternative_address_city = doc.data().alternative_address_city,
                this.alternative_address_zip = doc.data().alternative_address_zip,
                this.customer_signature = doc.data().customer_signature,
                this.customer_signature_timestamp = doc.data().customer_signature_timestamp
          })
        })
    },
    //Remove signature from pad canvas
    clearCustomerSignature() {
      this.$refs.signaturePad.clearSignature()
      this.dialog_signature = false
      this.customer_signature = null
    },
    //Save customer signature as variable to database
    saveCustomerSignature() {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
        console.log(isEmpty);
        console.log(data);
        //Create and save timestamp to database
        this.customer_signature_timestamp = `${new Date().toISOString().slice(0, 10)} at ${new Date().toISOString().slice(11, 16)}`;
        this.customer_signature = data
        showSnackbar("Customer signature has been saved");
    },
      //UPDATED SALES ORDER RELATED CODE
      //show the "View Updates" button in the snackbar
      updatedOnly(){
          if(this.sales_order_updated === true && this.sales_order_cancelled === false){
              return true
          }
      },
      //Update the work order to contain all the items of the sales order
      updateAllWorkOrderItems(){
          this.line_items = this.updated_line_items
          this.alertSnackbar = false
          this.dialog = false
          this.sales_order_updated = false
      },
      //Updates form values in the database
        updateDeliveryNote () {
            //Form validation --> ensures required fields aren't blank before continuing further
            if(this.$refs.form.validate()){
                this.loading_update = true
                db.collection('delivery_notes').where('delivery_id', '==', this.$route.params.delivery_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.update({
                            delivery_id: this.delivery_id,
                            delivery_note_number: this.delivery_note_number,
                            sales_order_number: this.sales_order_number,
                            sales_order_id: this.sales_order_id,
                            sales_order_updated: this.sales_order_updated,
                            sales_order_cancelled: this.sales_order_cancelled,
                            customer: this.customer,
                            delivery_dispatch_date: this.delivery_dispatch_date,
                            delivery_status: this.delivery_status,
                            delivery_special_instructions: this.delivery_special_instructions,
                            line_items: this.line_items,
                            delivery_date_created: this.delivery_date_created,
                            useAlternativeAddress: this.useAlternativeAddress,
                            alternative_address_line_1: this.alternative_address_line_1,
                            alternative_address_line_2: this.alternative_address_line_2,
                            alternative_address_city: this.alternative_address_city,
                            alternative_address_zip: this.alternative_address_zip,
                            customer_signature: this.customer_signature,
                            customer_signature_timestamp: this.customer_signature_timestamp
                        })
                    })
                })
                //Update sales order with delivery note details
                db.collection('sales_orders').where('order_id', '==', this.sales_order_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.update({
                            delivery_id: this.delivery_id,
                            delivery_status: this.delivery_status,
                            delivery_note_number: this.delivery_note_number,
                            delivery_date_created: this.delivery_date_created,
                            delivery_dispatch_date: this.delivery_dispatch_date
                        })
                        //Reroute to deliveries dashboard after database has been updated
                        .then(() => {
                            this.loading_update = false
                            showSnackbar("Delivery Note updated successfully");
                            //Reroute to work orders list after database is updated
                            this.$router.push('/deliveries')
                        })
                    })
                })    
            } 
        },
        //Removes delivery from database
        deleteDeliveryNote () {
            if(confirm('Are you sure you want to delete this Delivery Note?')){
                this.loading_delete = true
                db.collection('delivery_notes').where('delivery_id', '==', this.$route.params.delivery_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.delete()
                    })
                })
                //Reset delivery status on sales order
                //Remove delivery note ID from sales order
                    if(this.sales_order_cancelled === false){
                    db.collection('sales_orders').where('order_id', '==', this.sales_order_id).get()
                    .then(querySnapshot => {
                        querySnapshot.forEach(doc => {
                            doc.ref.update({
                                delivery_status: 'Not Started',
                                delivery_id: null,
                            })
                            //Reroute to sdeliveries page after database has been updated
                            .then(() => {
                                this.loading_delete = false
                                showSnackbar("Delivery Note deleted successfully");
                                //Reroute to work orders list after database is updated
                                this.$router.push('/deliveries')
                            })
                        })
                    })
                } else{
                    this.loading_delete = false
                    showSnackbar("Delivery Note deleted successfully");
                    this.$router.push('/deliveries')
                }
            }
        }
    }
}
</script>

<style scoped>
  .white-text{
    color: #fff;
  }
  #signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>