<template>
    <div>
         <v-container>
            <v-row class="mb-8">
                <v-col lg="12">
                    <v-card-title>
                        <h2>Deliveries</h2>
                        <!--Skeleton button-->
                        <v-skeleton-loader
                          :loading="loading_user"
                          type="button"
                          v-if="firstLoad"
                          class="ml-10"
                        >
                        </v-skeleton-loader>
                        <NewDeliveryNoteBtn
                          v-if="!firstLoad"
                        />
                        <v-spacer/>
                        <ButtonCTA
                            :button_name="viewDeliveryScheduleBtn.btn_delivery_schedule_name"
                            :button_to="viewDeliveryScheduleBtn.btn_delivery_schedule_to"
                            :button_color="secondaryCTA.btn_secondary_color"
                            :button_icon="secondaryCTA.btn_view_icon"
                            :button_margin_left="secondaryCTA.btn_ml_10"
                            v-if="!firstLoad"
                            v-show="this.user_roles_list.flat().includes('deliveries_read')"
                        >
                        </ButtonCTA>
                    </v-card-title>
                </v-col>
            </v-row>

            <!--Card skeleton loader-->
            <v-row class="mb-3" v-if="firstLoad">
              <v-col md="3" lg="3">
                <v-skeleton-loader :loading="loading_user" type="card" max-height="88">
                    </v-skeleton-loader>
              </v-col>
              <v-col md="3" lg="3">
                <v-skeleton-loader :loading="loading_user" type="card" max-height="88">
                    </v-skeleton-loader>
              </v-col>
              <v-col md="3" lg="3">
                <v-skeleton-loader :loading="loading_user" type="card" max-height="88">
                    </v-skeleton-loader>
              </v-col>
            </v-row>
             <!--Dashboard summary tiles-->
            <v-row class="mb-3" v-if="!firstLoad" v-show="this.user_roles_list.flat().includes('deliveries_read')">
              <v-col md="3" lg="3" v-for="item in dashboard_items" :key="item.name">
                <v-sheet elevation="2">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <v-icon
                          left
                          x-small
                          :color="item.color"
                        >
                          mdi-circle</v-icon>
                        {{item.name}}
                      </v-list-item-subtitle >
                      <v-list-item-title class="headline mb-1 font-weight-bold">
                        {{countDashboardItems(item.item_delivery_status)}}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-sheet>
              </v-col>
            </v-row>
            <!--Table skeleton loader-->
              <v-row v-if="firstLoad">
                <v-col>
                  <v-sheet elevation="4">
                    <v-skeleton-loader :loading="loading_user" type="table" max-height="290">
                    </v-skeleton-loader>
                  </v-sheet>
                </v-col>
              </v-row>
              <!--End of skeleton loader-->
            <v-row v-if="!firstLoad" v-show="this.user_roles_list.flat().includes('deliveries_read')">
              <v-col>
                    <!--Delivery Notes data table-->    
                    <v-sheet elevation="4">
                    <template>
                      <div>
                        <v-data-table
                          :headers="deliveryNoteTableHeaders"
                          :items="filteredItems"
                          :search="search"
                          :item-class= "row_classes"   
                        >
                      <!--Filter deliveries by status-->  
                      <template v-slot:top>
                        <v-container fluid>
                            <v-row>
                              <v-col lg="3" md="3">
                                <v-select
                                  :items="['Delivery Note Created','Ready For Dispatch','Dispatched', 'Completed', 'On Hold', 'Cancelled']"
                                  v-model='deliveryNoteStatus'
                                  label="Production Status"
                                  outlined
                                  flat
                                  solo
                                  dense
                                  append-icon="mdi-filter-variant"
                                >
                                </v-select>
                              </v-col> 
                              <v-spacer />
                              <!--Search bar filter -->
                              <v-col lg="4" md="4">
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                rounded
                                dense
                                filled
                                clearable
                                ></v-text-field>
                              </v-col>
                            </v-row>
                        </v-container> 
                      </template>

                      <!--Edit icon button-->
                      <template
                        v-slot:item.delivery_id={item}
                        v-if="this.user_roles_list.flat().includes('deliveries_write')"
                      >
                          <v-btn
                              :to="{ name: 'edit-delivery-note', params: { delivery_id: item.delivery_id }}"
                              color="#3d2cdd"
                              icon
                              small
                          ><v-icon small>mdi-pencil</v-icon></v-btn>
                      </template>
                      <!--Disable edit button based on user roles-->
                      <template
                        v-slot:item.delivery_id
                        v-else
                      >
                        <v-btn
                              color="#3d2cdd"
                              icon
                              small
                              disabled
                          ><v-icon small>mdi-pencil-off</v-icon></v-btn>
                      </template>
                      <!--Preview Work Order link-->
                      <template v-slot:item.delivery_note_number={item}>
                         <router-link
                            class="font-weight-medium"
                            style="text-decoration: none"
                            :to="{ name: 'view-delivery-note', params: { delivery_id: item.delivery_id }}">
                            {{item.delivery_note_number}}
                          </router-link>
                      </template>
                      <!--Delivery status formatting-->
                      <template v-slot:item.delivery_status="props">
                        <v-chip
                          class="status-chip"
                          label
                          :color="getColorDelivery(props.item.delivery_status)"
                          >
                          <v-avatar
                                left
                              >
                                <v-icon small>{{getDeliveryIcon(props.item.delivery_status)}}</v-icon>
                              </v-avatar>
                            {{ props.item.delivery_status}}
                        </v-chip>
                    </template>
                      
                      
                        </v-data-table>
                      </div>
                    </template>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import db from '../../../components/firebaseInit' //imports firebase database that was specified in firebaseInit.js
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import store from '../store/store'
import {mapState} from 'vuex'
import NewDeliveryNoteBtn from '../components/NewDeliveryNoteBtn'
import ButtonCTA from '../../../components/Buttons/ButtonCTA'

export default {
    name: 'DeliveryNotesTable',
    mixins: [mixin_UserRoles],
    components: {NewDeliveryNoteBtn, ButtonCTA},
    data(){
        return {
            delivery_notes: [],
            deliveryNoteStatus: 'Delivery Note Created',
            dashboard_items: [
              {name: 'Delivery Note Created', item_delivery_status: 'Delivery Note Created', color: '#c5d0ed'},
              {name: 'Ready For Dispatch', item_delivery_status: 'Ready For Dispatch', color: '#f9d493'},
              {name: 'Dispatched', item_delivery_status: 'Dispatched', color: '#88e2b7'}
            ],
            search: '',
        }
    },
    store,
    computed: mapState({
        //This is where data is retrieved from the Vuex state
        deliveryNoteTableHeaders: state => state.deliveryNoteTableHeaders,
        viewDeliveryScheduleBtn: state => state.viewDeliveryScheduleBtn,
        secondaryCTA: state => state.secondaryCTA,
        //Deliveries dashboard filter
        filteredItems() {
        return this.delivery_notes.filter((i) => 
          {
            return this.deliveryNoteStatus === '' || (i.delivery_status === this.deliveryNoteStatus)
          })
        },
    }),
    //Get work orders from database
    created(){
        db.collection('delivery_notes').orderBy('delivery_note_number').get().then(querySnapshot => {
          this.delivery_notes = []
          querySnapshot.forEach(doc => {
            console.log(doc.id)
            const data = {
              'id': doc.id,
              'delivery_id': doc.data().delivery_id,
              'delivery_note_number': doc.data().delivery_note_number,
              'sales_order_number': doc.data().sales_order_number,
              'sales_order_id': doc.data().sales_order_id,
              'sales_order_updated': doc.data().sales_order_updated,
              'sales_order_cancelled': doc.data().sales_order_cancelled,
              'delivery_dispatch_date': doc.data().delivery_dispatch_date,
              'delivery_status':doc.data().delivery_status
            }
            //Pushes the delivery note objects to the empty delivery notes array above
            this.delivery_notes.push(data)
          })
        })
      },
      methods: {
      //  Calculates total count of deliveries for each delivery status
      //  Displays them on dashbaord summary pannel 
      countDashboardItems(item_delivery_status){
        const statusCount = this.delivery_notes
      .filter(delivery_notes => delivery_notes.delivery_status === item_delivery_status)
       return statusCount.length
      },
      //Changes row background color depending on sales order updates or cancellations
      //Cancelled status has higher priority than updated status
        row_classes(item) {
          if (item.sales_order_cancelled === true){
            return "pink lighten-4"
          }
          else if (item.sales_order_updated === true) {
            return "orange lighten-4"
          } 
          else return "white"
        },
        //Conditional formatting for deliveries
        getColorDelivery (delivery_status) {
          if (delivery_status == "Not Started") return ''
          else if (delivery_status == "Delivery Note Created") return '#c5d0ed'
          else if (delivery_status == "Ready For Dispatch" ||delivery_status == "Dispatched") return '#f9d493'
          else if (delivery_status == "Completed") return '#88e2b7'
          else if (delivery_status == "On Hold") return '#ef9ebd'
          else if (delivery_status == "Cancelled") return '#ce2458'
          else return 'white'
        },
        getDeliveryIcon (delivery_status) {
          if (delivery_status == "Not Started") return 'mdi-sleep'
          else if (delivery_status == "Delivery Note Created") return 'mdi-clipboard'
          else if (delivery_status == "Ready For Dispatch") return 'mdi-truck'
          else if (delivery_status == "Dispatched") return 'mdi-truck-delivery'
          else if (delivery_status == "Completed") return 'mdi-check'
          else if (delivery_status == "On Hold") return 'mdi-pause'
          else if (delivery_status == "Cancelled") return 'mdi-cancel'
          else return 'white'
        },
    }
}
</script>

<style scoped>
  .white-text{
    color: #fff;
  }
</style>