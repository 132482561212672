<template>
    <v-container  fluid>
            <v-row dense>
                <v-col lg="8" offset-lg="2">
                <v-card-title>
                <v-btn
                    to="/deliveries"
                    color="#ffffff"
                    light
                    elevation="0"
                >
                <v-icon left large>mdi-chevron-left</v-icon>
                </v-btn>
                <h3>Delivery Summary</h3>
                <!--Generate PDF button-->
                <v-spacer></v-spacer>
                    <v-btn
                        elevation="2"
                        color="#e7e7f7"
                        light
                        class="ml-10"
                        @click="generateDeliveryNote"
                    >
                        <v-icon left>mdi-printer</v-icon>
                        Print Delivery Note
                    </v-btn>
                </v-card-title>
                <!--Alert for Updates and Cancellations-->
                <v-alert
                    v-if="sales_order_cancelled || sales_order_updated"
                    :color="alertSnackbarColor"
                    class="text-center"
                    dark
                >
                    <v-icon left small>mdi-alert</v-icon>
                    Sales Order {{sales_order_number}} has been {{cancelledOrUpdated}}
                </v-alert>
                <v-sheet elevation="2" class="pa-5">
                    <v-container>
                        <!--Document title-->
                        <v-row>
                            <v-col sm="9" md="9">
                                <h1>Delivery Note {{delivery_note_number}}</h1>
                                <v-img
                                    :src="company_logo"
                                    max-width="150"
                                ></v-img>
                            </v-col>
                            <v-spacer></v-spacer>
                            <!--Company Details-->
                            <v-col sm="3" md="3" class="text-right caption">
                                <v-col>
                                {{company_legal_name}}<br>
                                <span v-if="company_trading_as != null">T/A {{company_trading_as}}</span><br>
                                Reg No. {{company_reg_number}}<br>
                                VAT No. {{company_VAT_number}}
                                </v-col>
                                <v-col>
                                    {{company_physical_address_line_1}}<br>
                                    {{company_physical_address_line_2}}<br>
                                    {{company_physical_address_city}} {{company_physical_address_zip}}<br>
                                </v-col>
                            </v-col>
                        </v-row>
                        <!--Divider-->
                        <v-row class="my-3">
                            <v-col>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <!--Work Order details-->
                        <v-row>
                            <v-col sm="4" md="4">
                                <h4>Delivery Note No.</h4>
                            </v-col>
                           <v-col sm="4" md="4">
                                <h4>Issued By</h4>
                            </v-col>
                            <v-col sm="4" md="4">
                                <h4>Dispatch Date</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="4" md="4">
                                {{delivery_note_number}}
                            </v-col>
                            <v-col sm="4" md="4">
                                {{delivery_created_by_name}}<br>
                                {{delivery_date_created}}
                            </v-col>
                            <v-col sm="4" md="4">
                                {{delivery_dispatch_date}}
                            </v-col>
                        </v-row>
                        <!--Divider-->
                        <v-row class="my-3">
                            <v-col>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <!--Sales Details-->
                        <v-row>
                            <v-col sm="4" md="4">
                                <h4>Sales Order No.</h4>
                            </v-col>
                            <v-col sm="4" md="4">
                                <h4>Customer</h4>
                            </v-col>
                            <v-col sm="4" md="4">
                                <h4>Delivery Address</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="4" md="4">
                                <router-link
                                    :to="{ name: 'view-order', params: { order_id: sales_order_id }}">
                                    {{sales_order_number}}
                                </router-link>
                            </v-col>
                            <v-col sm="4" md="4">
                                {{customer.customer_name}}<br>
                                {{customer.customer_cell}}<br>
                                {{customer.customer_email}}<br>
                            </v-col>
                            <v-col sm="4" md="4">
                                <!--Default address-->
                                <div v-if="!useAlternativeAddress">
                                    {{customer.customer_addr_1}}<br>
                                    {{customer.customer_addr_2}}<br>
                                    {{customer.customer_city}} {{customer.customer_zip}}<br>
                                </div>
                                <!--Alternative address-->
                                <div v-if="useAlternativeAddress">
                                    {{alternative_address_line_1}}<br>
                                    {{alternative_address_line_2}}<br>
                                    {{alternative_address_city}} {{alternative_address_zip}}<br>
                                </div>
                            </v-col>
                        </v-row>
                        <!--Divider-->
                        <v-row class="my-3">
                            <v-col>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <!--Line items-->
                        <v-row>
                             <v-col>
                                 <h3>Line Items</h3>
                             </v-col>
                         </v-row>
                        <v-row>
                            <v-col sm="6" md="6">
                                <h4>Item Name</h4>
                            </v-col>
                            <v-col sm="2" md="2" class="text-center">
                                <h4>Qty Delivered</h4>
                            </v-col>
                        </v-row>
                        <v-row v-for="item in line_items" :key="item.product_name">
                            <v-col sm="6" md="6">
                                {{item.product_name}}
                            </v-col>
                            <v-col sm="2" md="2" class="text-center">
                                {{item.item_qty}}
                            </v-col>
                        </v-row>
                        <!--Divider-->
                        <div v-if="customer_signature">
                        <v-row class="my-3">
                            <v-col>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                         <v-row>
                             <v-col sm="6" md="6">
                                 <v-img
                                    max-width="250"
                                    :src="customer_signature"
                                ></v-img><br>
                                <span class="font-italic">Goods received in good condition by
                                    <span
                                        v-if="!receipient_name"
                                        class="font-weight-bold"
                                    >{{customer.customer_name}}</span>
                                    <span
                                        v-else
                                        class="font-weight-bold">{{receipient_name}}</span><br>
                                    on {{customer_signature_timestamp}}</span>
                             </v-col>
                         </v-row>
                        </div>
                        <!--Edit button-->
                        <v-row>
                            <v-col>
                                <v-card-actions class="mt-3">
                                    <v-spacer></v-spacer>
                                        <v-btn
                                            dark
                                            text
                                            color="#33cc99"
                                            :to="{ name: 'edit-delivery-note', params: { delivery_id: delivery_id }}"
                                        >
                                            <v-icon left>mdi-pencil</v-icon>
                                            Edit
                                        </v-btn>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-sheet>
                </v-col>
            </v-row>
    </v-container>
</template>

<script>
import db from '../../../components/firebaseInit'
//Mixins
import mixin_InitDeliveryNote from '../mixins/mixin_InitDeliveryNote'
import mixin_CompanyProfile from '../../../globalActions/mixin_CompanyProfile'
import mixin_PDFdeliveryNote from '../mixins/mixin_PDFdeliveryNote'

export default {
    name: 'ViewDeliveryNote',
    mixins: [mixin_InitDeliveryNote, mixin_CompanyProfile, mixin_PDFdeliveryNote],
    data(){
        return{
            delivery_created_by_name: null,
        }
    },
    computed: {
        cancelledOrUpdated(){
            return this.sales_order_cancelled === true ? "cancelled or deleted" : "updated"
        },
        alertSnackbarColor(){
            return this.sales_order_cancelled === true ? "#ce2458" : "#FFB300"
        },
    },
    //Database query that runs before the page loads
    beforeRouteEnter (to, from, next){
        db.collection('delivery_notes').where('delivery_id', '==', to.params.delivery_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc =>{
                //vm assigns all the values we want to put in the template
                next(vm => {
                    //Sets template values to those stored in the database
                    vm.delivery_id = doc.data().delivery_id
                    vm.delivery_note_number = doc.data().delivery_note_number
                    vm.sales_order_number = doc.data().sales_order_number
                    vm.sales_order_id = doc.data().sales_order_id 
                    vm.sales_order_updated = doc.data().sales_order_updated 
                    vm.sales_order_cancelled = doc.data().sales_order_cancelled
                    vm.updated_line_items = doc.data().updated_line_items
                    vm.customer = doc.data().customer 
                    vm.delivery_dispatch_date = doc.data().delivery_dispatch_date 
                    vm.delivery_status = doc.data().delivery_status 
                    vm.delivery_special_instructions = doc.data().delivery_special_instructions 
                    vm.line_items = doc.data().line_items 
                    vm.delivery_date_created = doc.data().delivery_date_created
                    vm.useAlternativeAddress = doc.data().useAlternativeAddress,
                    vm.alternative_address_line_1 = doc.data().alternative_address_line_1,
                    vm.alternative_address_line_2 = doc.data().alternative_address_line_2,
                    vm.alternative_address_city = doc.data().alternative_address_city,
                    vm.alternative_address_zip = doc.data().alternative_address_zip,
                    vm.customer_signature = doc.data().customer_signature,
                    vm.recepient_name = doc.data().recepient_name,
                    vm.customer_signature_timestamp = doc.data().customer_signature_timestamp,
                    vm.delivery_created_by_name = doc.data().delivery_created_by_name    
                })
            })
        })
    },
    watch: {
        '$route': 'fetchData'
    },
    methods: {
        //Maps database data to template relative to the work order selected in this route
        fetchData (){
        db.collection('delivery_notes').where('delivery_id', '==', this.$route.params.delivery_id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.delivery_id = doc.data().delivery_id
                this.delivery_note_number = doc.data().delivery_note_number
                this.sales_order_number = doc.data().sales_order_number 
                this.sales_order_id = doc.data().sales_order_id  
                this.sales_order_cancelled = doc.data().sales_order_cancelled
                this.sales_order_updated = doc.data().sales_order_updated
                this.updated_line_items = doc.data().updated_line_items
                this.customer = doc.data().customer
                this.delivery_dispatch_date = doc.data().delivery_dispatch_date
                this.delivery_status = doc.data().delivery_status 
                this.delivery_special_instructions = doc.data().delivery_special_instructions
                this.line_items = doc.data().line_items
                this.delivery_date_created = doc.data().delivery_date_created  
                this.useAlternativeAddress = doc.data().useAlternativeAddress,
                this.alternative_address_line_1 = doc.data().alternative_address_line_1,
                this.alternative_address_line_2 = doc.data().alternative_address_line_2,
                this.alternative_address_city = doc.data().alternative_address_city,
                this.alternative_address_zip = doc.data().alternative_address_zip,
                this.customer_signature = doc.data().customer_signature,
                this.recepient_name = doc.data().recepient_name,
                this.customer_signature_timestamp = doc.data().customer_signature_timestamp
                this.delivery_created_by_name = doc.data().delivery_created_by_name
          })
        })
      },
    }
}
</script>