//This JS file is accessed in the Vuex state block
//It is then passed as a function to the parent component 
//Values are then assigned to component propss

//----------------------------------------------
//BUTTONS DATA
//Primary button data
const primaryCTA = {                
    btn_primary_color: '#33cc99',
    btn_plus_icon: 'mdi-plus',
    btn_ml_10: 'ml-10',
}
//Secondary button data
const secondaryCTA = {
    btn_secondary_color: '#e7e7f7',
    btn_ml_10: 'ml-10',
    btn_view_icon: 'mdi-calendar',
}
//Add customer button data
const addDeliveryBtn = {
    btn_add_customer: 'Add Delivery',
    btn_new_customer_to: '/new-delivery-note',
}

//DATA TABLE HEADERS
//Work Orders table
const deliveryNoteTableHeaders = [
    { text: 'Edit', value: 'delivery_id', align: 'start' },
    { text: 'Delivery Note #', sortable: true, value: 'delivery_note_number' },
    { text: 'Sales Order #', value: 'sales_order_number' },
    { text: 'Dispatch Date', value: 'delivery_dispatch_date' },
    { text: 'Delivery Status', value: 'delivery_status'},
]

//----------------------------------------------

export {
    //BUTTONS
    primaryCTA, secondaryCTA, addDeliveryBtn,
    //DATA TABLE HEADERS
    deliveryNoteTableHeaders,

    //FORM HEADERS
}