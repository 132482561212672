<template>
    <v-container  fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('deliveries_write')">
        <v-col lg="8" offset-lg="2">
            <v-card-title>
                    <v-btn
                        to="/deliveries"
                        color="#ffffff"
                        light
                        elevation="0"
                    >
                    <v-icon left large>mdi-chevron-left</v-icon>
                    </v-btn>
                    <h3>Create New Delivery Note</h3>
                    <!--Show secondary action button-->
                    <v-spacer></v-spacer>
                            <v-btn
                                elevation="2"
                                color="#e7e7f7"
                                light
                                class="ml-10"
                                @click="generateDeliveryNote"
                            >
                                <v-icon left>mdi-printer</v-icon>
                                Print Delivery Note
                            </v-btn>
                </v-card-title>
            <v-card>
                <!--New Delivery Note form -->
                <v-form @submit.prevent="saveDeliveryNote" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <h4>Delivery Note Details</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <!--Delivery Note number-->
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    :value="delivery_note_number"
                                    label="Delivery Note #"
                                    readonly
                                    single-line
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <!--Sales Order # is filled in automatically-->
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    v-model="sales_order_number"
                                    readonly
                                    cache-items
                                    label="Sales Order #"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <!--Delivery Due Date Picker-->
                            <v-col cols="12" sm="6" md="6">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="true"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="delivery_dispatch_date"
                                        label="Dispatch Date"
                                        append-icon="mdi-calendar"
                                        
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        dense
                                        outlined
                                        clearable
                                        color="#3d2cdd"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="delivery_dispatch_date"
                                        no-title
                                        scrollable
                                        color="#3d2cdd"
                                        show-adjacent-months
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="#ce2458"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="#33cc99"
                                        @click="$refs.menu.save(date)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <!--Work Order status-->
                            <v-col cols="12" sm="12" md="6">
                                <v-select
                                    v-model="delivery_status"
                                    required
                                    :items="delivery_status_items"
                                    label="Delivery Status"
                                    color="#3d2cdd"
                                    outlined
                                    dense
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-switch
                                    v-model="useAlternativeAddress"
                                    label="Use alternative address"
                                    color="#33cc99"
                                ></v-switch>
                            </v-col>
                        </v-row>
                        <div v-if="useAlternativeAddress">
                        <!--Alternative address-->
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <h4>Alternative Address</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                    v-model="alternative_address_line_1"
                                    label="Address Line 1"
                                    append-icon="mdi-map-marker"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                    v-model="alternative_address_line_2"
                                    label="Address Line 2"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                             <v-col cols="12" sm="8" md="8">
                                 <v-text-field
                                    v-model="alternative_address_city"
                                    label="City"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                             </v-col>
                             <v-col cols="12" sm="4" md="4">
                                 <v-text-field
                                    v-model.number="alternative_address_zip"
                                    type="number"
                                    label="ZIP Code"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                             </v-col>
                        </v-row>
                        </div>
                        <!--End of alternative address-->
                        <!--Line Items-->
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <h4>Line Items</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-form v-for="item in line_items" :key="item.product_name">
                                    <v-container>
                                        <v-row>
                                            <!--Product Name-->
                                            <v-col sm="4" md="4">
                                                <v-text-field
                                                    v-model="item.product_name"
                                                    label="Item"
                                                    color="#3d2cdd"
                                                    readonly
                                                />
                                            </v-col>
                                            <!--Quantity-->
                                            <v-col sm="2" md="2">
                                                <v-text-field
                                                    v-model.number="item.item_qty"
                                                    label="Qty Delivered"
                                                    color="#3d2cdd"
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                    outlined
                                                    dense
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-col>
                        </v-row>
                        <!--Special instructions-->
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <h4>Special Instructions (Notes)</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea
                                    v-model="delivery_special_instructions"
                                    clearable
                                    counter
                                    color="#3d2cdd"
                                    outlined
                                    dense
                                >

                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/deliveries'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                        @click="saveDeliveryNote"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Submit
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <div v-else>
        <NoPermissionsCard/>
    </div>
</v-container>
</template>

<script>
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import {showSnackbar} from '../../../globalActions/index'
//Mixins
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import mixin_InitDeliveryNote from '../mixins/mixin_InitDeliveryNote'
import mixinCompanyProfile from '../../../globalActions/mixin_CompanyProfile'
import mixin_PDFdeliveryNote from '../mixins/mixin_PDFdeliveryNote'
//Components
import NoPermissionsCard from '../../../components/Cards/NoPermissionsCard'
export default {
    name: 'NewDeliveryNote',
    mixins: [
        mixin_UserRoles, mixin_InitDeliveryNote, mixin_PDFdeliveryNote,
        mixinCompanyProfile
    ],
    components: {NoPermissionsCard},
    data(){
        return{
            loading: false,
            delivery_created_by_id: null,
            delivery_created_by_name: null,
        }
    },
    //Database query that runs before the page loads
    beforeRouteEnter (to, from, next){
        db.collection('sales_orders').where('order_id', '==', to.params.order_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc =>{
                //vm assigns all the values we want to put in the template
                next(vm => {
                    //Sets template values to those stored in the database
                    vm.sales_order_id = doc.data().order_id
                    vm.customer = doc.data().customer
                    vm.sales_order_number = doc.data().order_number
                    vm.line_items = doc.data().line_items 
                })
            })
        })
    },
    watch: {
        '$route': 'fetchData'
    },
    created(){
        //Get delivery note counters from database
        db.collection('delivery_note_number_counter')
        .doc('8VrKaINy1ldjCCXhm7jI').get().then(snapshot => {
            // Gets counter object from datatbase
            const count = snapshot.data().delivery_note_count
            this.delivery_note_number = "DN-".concat(count.toString())      
      }),
        //Captures user that created the sales order
        this.delivery_created_by_id = firebase.auth().currentUser.uid
        db.collection('users').where('uid', '==', this.delivery_created_by_id).get().then(querySnapshot => {
        querySnapshot.forEach(doc => {
            this.delivery_created_by_name = doc.data().displayName
            })
        })
    },
    methods: {
        saveDeliveryNote () {
            //Form validation --> ensures required fields aren't blank before continuing further
            if(this.$refs.form.validate()){
                this.loading = true
                var newDocRef = db.collection('delivery_notes').doc();
                    newDocRef.set({
                    //Populated by the v-model values in the form
                    //NB: Sets the order_id == to the document id in database
                    delivery_id: newDocRef.id,
                    delivery_note_number: this.delivery_note_number,
                    sales_order_number: this.sales_order_number,
                    sales_order_id: this.sales_order_id,
                    sales_order_updated: this.sales_order_updated,
                    sales_order_cancelled: this.sales_order_cancelled,
                    customer: this.customer,
                    delivery_dispatch_date: this.delivery_dispatch_date,
                    delivery_status: this.delivery_status,
                    delivery_special_instructions: this.delivery_special_instructions,
                    line_items: this.line_items,
                    delivery_date_created: new Date().toISOString().slice(0, 10),
                    delivery_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    useAlternativeAddress: this.useAlternativeAddress,
                    alternative_address_line_1: this.alternative_address_line_1,
                    alternative_address_line_2: this.alternative_address_line_2,
                    alternative_address_city: this.alternative_address_city,
                    alternative_address_zip: this.alternative_address_zip,
                    delivery_created_by_id: this.delivery_created_by_id,
                    delivery_created_by_name: this.delivery_created_by_name,
                })
                .then(() => {
                    const db = firebase.firestore();
                        //Delivery Note number counter
                        //A counter in a doc in firebase is incremented when a new delivery note is generated
                        //The latest count is then used as the next delivery note number
                        const increment = firebase.firestore.FieldValue.increment(1);
                        const deliveryNoteNumberCount = db.collection('delivery_note_number_counter').doc('8VrKaINy1ldjCCXhm7jI');
                        //Increments work order counter
                        deliveryNoteNumberCount.update({ delivery_note_count: increment });

                //Add work order details to sales order
                db.collection('sales_orders').where('order_id', '==', this.sales_order_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.update({
                            delivery_id: newDocRef.id,
                            delivery_status: this.delivery_status,
                            delivery_note_number: this.delivery_note_number,
                            delivery_date_created: this.delivery_date_created,
                            delivery_dispatch_date: this.delivery_dispatch_date,
                            delivery_created_by_id: this.delivery_created_by_id,
                            delivery_created_by_name: this.delivery_created_by_name,
                        })
                        
                    })
                })
                    .then(() => {
                        this.loading = false
                        showSnackbar("New Delivery Note created successfully!");
                        //Reroute to deliveries table after database is updated
                        this.$router.push('/deliveries')
                    })  
                })
                
            }
        }
    }
}
</script>