<template>
  <v-container>
            <v-row class="mb-8">
                <v-col lg="12">
                    
                        <v-card-title>
                            <v-btn
                            to="/deliveries"
                            color="#ffffff"
                            light
                            elevation="0"
                        >
                        <v-icon left large>mdi-chevron-left</v-icon>
                        </v-btn>
                        <h2>Delivery Schedule</h2>
                        </v-card-title>
                        <!--Calendar controls-->
                </v-col>
            </v-row>
            <v-row>
                <v-col :lg="changeColWidth">
                        <v-card>
                        <v-toolbar
                            flat
                            >
                            <v-btn outlined class="mr-4" color="grey darken-2"
                                @click="setToday"
                            >
                                Today
                            </v-btn>
                            <v-btn fab text small color="grey darken-2"
                                @click="prev"
                            >
                                <v-icon small>
                                mdi-chevron-left
                                </v-icon>
                            </v-btn>
                            <v-btn fab text small color="grey darken-2"
                                @click="next"
                            >
                                <v-icon small>
                                mdi-chevron-right
                                </v-icon>
                            </v-btn>
                            <v-toolbar-title v-if="$refs.calendar">
                                {{ $refs.calendar.title }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-menu
                                bottom
                                right
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    outlined
                                    color="grey darken-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <span>{{ typeToLabel[type] }}</span>
                                    <v-icon right>
                                    mdi-menu-down
                                    </v-icon>
                                </v-btn>
                                </template>
                                <v-list>
                                <v-list-item @click="type = 'day'">
                                    <v-list-item-title>Day</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'week'">
                                    <v-list-item-title>Week</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'month'">
                                    <v-list-item-title>Month</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = '4day'">
                                    <v-list-item-title>4 days</v-list-item-title>
                                </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-toolbar>
                        <!--End of calendar controls-->
                        <v-sheet height="600">
                        <v-calendar
                            ref="calendar"
                            v-model="focus"
                            :events="delivery_notes"
                            :event-color="getEventColor"
                            first-interval="8"
                            interval-count="24"
                            :type="type"
                            event-text-color="#1a1b1f"
                            @click:event="showEvent"
                            @click:more="viewDay"
                            @click:date="viewDay"
                        ></v-calendar>
                        <!--Delivery Note preview modal-->
                    </v-sheet>
                    </v-card>
                </v-col>
                <!--Work order preview content-->
                <v-scroll-y-transition mode="out-in">
                    <v-col lg="4" v-if="selectedEvent.delivery_note_number">
                        <v-sheet>
                            <v-card
                                class="py-5"
                            >
                                <v-scroll-y-transition mode="out-in">
                                    <div
                                    :key="selectedEvent.delivery_note_number"
                                    class="mx-5"
                                    >
                                    <v-card-title>
                                        <h3>{{selectedEvent.delivery_note_number}}</h3>
                                    </v-card-title>
                                    </div>
                                </v-scroll-y-transition>
                            </v-card>
                        </v-sheet>
                    </v-col>
                </v-scroll-y-transition>
                <!--end of work order preview content-->
            </v-row>
  </v-container>
</template>

<script>
import db from '../../../components/firebaseInit' //imports firebase database that was specified in firebaseInit.js

export default {
    name: 'DeliverySchedule',

    data: () => ({
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      delivery_notes: [],
    }),
    mounted () {
      this.$refs.calendar.checkChange()
    },
    //Get delivery notes from database
    created(){
        db.collection('delivery_notes').orderBy('delivery_note_number').get().then(querySnapshot => {
          querySnapshot.forEach(doc => {
            console.log(doc.id)
            const data = {
                'id': doc.id,
                'delivery_id': doc.data().delivery_id,
                'delivery_note_number': doc.data().delivery_note_number,
                'sales_order_number': doc.data().sales_order_number,
                'sales_order_id': doc.data().sales_order_id,
                'sales_order_updated': doc.data().sales_order_updated,
                'sales_order_cancelled': doc.data().sales_order_cancelled,
                'delivery_dispatch_date': doc.data().delivery_dispatch_date,
                'delivery_status':doc.data().delivery_status,
                'start': doc.data().delivery_dispatch_date,
                'name': doc.data().delivery_note_number,
            }
            ///Pushes the delivery note objects to the empty delivery notes array above
            this.delivery_notes.push(data)
            })
        })
    },
    methods: {
        viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        if(event.delivery_status === 'Delivery Note Created'){
            return '#c5d0ed'
        }else if(event.delivery_status === 'Ready For Dispatch' || event.delivery_status === 'Dispatched'){
            return '#f9d493'
        }else if(event.delivery_status === 'Completed'){
            return '#88e2b7'
        }else if(event.delivery_status === 'On Hold'){
            return '#ef9ebd'
        }else if(event.delivery_status === 'Cancelled'){
            return '#ce2458'
        }
      },
      //  Changes width of Calendar column to make space for Work Order
    //  preview
      changeColWidth(){
          if (this.selectedEvent.delivery_note_number == true){
              return 8
          }else{
              return 12
          }
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => {
            this.selectedOpen = true
          }, 10)
        }
        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }
        nativeEvent.stopPropagation()
      },
    }
}
</script>