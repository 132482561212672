//Initialise form values
const mixin_InitDeliveryNote = {
    data(){
        return{
            delivery_id: null,
            delivery_note_number: null,
            sales_order_number: null,
            sales_order_id: null,
            customer: null,
            customer_name: null,
            sales_order_updated: null,
            sales_order_cancelled: null,
            delivery_dispatch_date: null,
            delivery_date_created: null,
            delivery_timestamp: null,
            useAlternativeAddress: false,
            alternative_address_line_1: null,
            alternative_address_line_2: null,
            alternative_address_city: null,
            alternative_address_zip: null,
            customer_signature: null,
            recepient_name: null,
            customer_signature_timestamp: null,

            //Date picker values
            menu: null,
            date: null,
            delivery_status: 'Delivery Note Created',
            delivery_status_items: [
                {text: 'Not Started', value: 'Not Started', disabled: false},
                {text: 'Delivery Note Created', value: 'Delivery Note Created', disabled: false},
                {text: 'Ready For Dispatch', value: 'Ready For Dispatch', disabled: false},
                {text: 'Dispatched', value: 'Dispatched', disabled: false},
                {text: 'Completed', value: 'Completed', disabled: false}
            ],
            delivery_special_instructions: null,
            line_items: [],
            //Form validation rules
            valid: false,
            RequireFormInput(propertyType) {
                return v => v && v.length !== null || `You must specify a ${propertyType}`
            },
            minLength(propertyType, minLength){
                return v => v && v.length >= minLength || `The ${propertyType} must be at least ${minLength} characters long`
            },
            maxLength(propertyType, maxLength){
                return v => v.length <= maxLength || `The ${propertyType} can't be more than ${maxLength} characters long`
            }
        }
    }
}

export default mixin_InitDeliveryNote