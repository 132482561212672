<template>
    <DeliveryNotesTable />  
</template>

<script>
import DeliveryNotesTable from '../components/DeliveryNotesTable'

export default {
  name: 'Deliveries',
  components: {DeliveryNotesTable}
}
</script>