import Vue from 'vue';
import Vuex from 'vuex';
//Import component state values
import {
    
} from '../data/data'

Vue.use(Vuex);

import {
    //BUTTONS
    primaryCTA, secondaryCTA, addDeliveryBtn,

    //DATA TABLE HEADERS
    deliveryNoteTableHeaders,

    //FORM HEADERS
    
} from '../data/data'

export default new Vuex.Store({
    state: {
    //BUTTONS
    primaryCTA, secondaryCTA, addDeliveryBtn,
    viewDeliveryScheduleBtn: {
        btn_delivery_schedule_name: 'Delivery Schedule',
        btn_delivery_schedule_to: '/delivery-schedule',
    },
    viewWorkOrderDashboardBtn: {
        btn_deliveries_dashboard_name: 'Deliveries Dashboard',
        btn_deliveries_dashboard_to: '/deliveries',
    },

    //DATA TABLE HEADERS
    deliveryNoteTableHeaders,

    //FORM HEADERS
    },
    mutations: {},
    actions: {},
    modules: {},
});